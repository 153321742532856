import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import PropTypes from 'prop-types';


ModalConfimacao.propTypes = {

    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
    funcPos: PropTypes.func,

    msgModalConfirmarAcao: PropTypes.string

}

export default function ModalConfimacao({ showModal, setShowModal, funcPos, msgModalConfirmarAcao }) {

    return(
        <Modal
        show={showModal}
        onHide={() => setShowModal(false) }
        keyboard={false}
        id="modalConfirmarAcao"
        centered
        >
            <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row style={{marginBottom: "5%"}}>
                        <Col style={{textAlign: "center", alignItems: "center"}}><h3>{msgModalConfirmarAcao}</h3></Col>
                    </Row>
                    <Row >

                        <Col>
                            <div className="d-grid gap-2">
                                <Button
                                variant="success"
                                onClick={() => {
                                    funcPos(); 
                                    setShowModal(false);
                                }}
                                >Sim</Button>{' '}
                            </div>
                        </Col>
                        <Col>
                            <div className="d-grid gap-2">
                                <Button
                                variant="danger"
                                onClick={() => {
                                    setShowModal(false);
                                }}
                                >Não</Button>{' '}
                            </div>
                        </Col>

                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );

}
