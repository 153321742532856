import { 
    Button, 
    Container, 
    Row, 
    Col, 
    Modal,
    Form,
    FloatingLabel
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import ModalErro from './ModalErro';
import React from 'react';

ModalMotivoCancelamento.propTypes = {

    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
    motivoCancelamento: PropTypes.string.isRequired,
    setMotivoCancelamento: PropTypes.func.isRequired,
    funcPos: PropTypes.func

}

export default function ModalMotivoCancelamento({ showModal, setShowModal, motivoCancelamento, setMotivoCancelamento, funcPos}) {

    const [showModalErro, setShowModalErro] = React.useState(false);
    const [msgErro, setMsgErro] = React.useState("");

    return (

        <Modal
        show={showModal}
        onHide={() => { setShowModal(false) }}
        centered
        size='lg'
        >
            <Modal.Header closeButton={true}>
                
                <Modal.Title>
                    
                    <h4>Motivo do cancelamento da NFC-e (opcional):</h4> 
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>

                    <ModalErro
                        showModal={showModalErro}
                        setShowModal={setShowModalErro}
                        msg={msgErro}
                        permiteFechar={false}
                        
                    />

                    <Row>
                        <Col>
                        
                            <FloatingLabel
                                controlId="floatingInputMotivoCancelamento"
                                label="Motivo do cancelamento: "
                                className="mb-3"
                            >
                                <Form.Control 
                                    type={'text'}
                                    value={motivoCancelamento}
                                    onChange={(e) => {
                                        setMotivoCancelamento(e.target.value)
                                    }}
                                />
                            </FloatingLabel>
                        
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="d-grid gap-2">
                                <Button
                                variant="success"
                                onClick={() => {
                                    if(motivoCancelamento.length >= 15){
                                        funcPos(motivoCancelamento);
                                        setShowModal(false);
                                    }else{
                                        setMsgErro("O motivo deve ter ao menos 15 caracteres!");
                                        setShowModalErro(true);
                                    }
                                    
                                }}
                                >Enviar Cancelamento</Button>{' '}
                            </div>
                        </Col>
                    </Row>
                </Container>
                
            </Modal.Body>
        </Modal>

    );

}
