import React, { useEffect } from 'react';
import * as SignalR from '@microsoft/signalr'
import { 
    Button, 
    Form, 
    Container, 
    Row, 
    Col, 
    FloatingLabel 
} from 'react-bootstrap';
import axios from 'axios';
import * as Sentry from "@sentry/react";
import global from '../global';
import ModalLoad from '../components/ModalLoad';
import ModalSucesso from '../components/ModalSucesso';
import ModalErro from '../components/ModalErro';
import NavbarOp from '../components/Navbar';
import ModalEmail from '../components/ModalEmail';
import ModalMotivoCancelamento from '../components/ModalMotivoCancelamento';

export default function ListaNFCe() {

    //user op
    const [user] = React.useState( JSON.parse(sessionStorage.getItem("informacoesUsuario")) );

    const [numNFCe, setnumNFCe] = React.useState(0);
    const [documentoCliente, setDocumentoCliente] = React.useState("");
    const [dateDe, setdateDe] = React.useState( new Date().getFullYear().toString() + "-" + ( (new Date().getMonth() + 1) <= 9 ? ("0" + (new Date().getMonth() + 1).toString()) : new Date().getMonth() + 1).toString()  + "-" + ( (new Date().getDate()) <= 9 ? ("0" + new Date().getDate().toString()) : (new Date().getDate().toString()) ).toString() );
    const [dataAte, setdataAte] = React.useState( new Date().getFullYear().toString() + "-" + ( (new Date().getMonth() + 1) <= 9 ? ("0" + (new Date().getMonth() + 1).toString()) : new Date().getMonth() + 1).toString()  + "-" + ( (new Date().getDate()) <= 9 ? ("0" + new Date().getDate().toString()) : (new Date().getDate().toString()) ).toString() );
    const [nfces, setnfces] = React.useState([]);

    //modal motivo
    const [showModalMotivoCancelamento, setShowMOdalMotivoCancelamento] = React.useState(false);
    const [motivoCancelamento, setMotivoCancelamento] = React.useState("");
    const [funcPosMotivoCancelamento, setFuncPosMotivoCancelamento] = React.useState(() => () => {});

    console.log("NOTAS NFCEs", nfces);

    //modal load
    const [showModalLoad, setShowModalLoad] = React.useState(false);

    //modal sucesso
    const [showModalSucesso, setShowModalSucesso] = React.useState(false);
    const [msgSucesso, setMsgSucesso] = React.useState("");

    //modal erro
    const [showModalErro, setShowModalErro] = React.useState(false);
    const [msgErro, setMsgErro] = React.useState("");

    //modal email
    const[showModalEmail, setShowModalEmail] = React.useState(false);
    const[funcPosEmail, setFuncPosEmail] = React.useState(() => () => {});
    const[email, setEmail] = React.useState("");

    useEffect(() => {

        if(user === null || user === undefined){
            window.location.href = "/login";
        }else{
            if(user.autorizado === false){
                window.location.href = "/login";
            }else{
    
                let connection = new SignalR.HubConnectionBuilder()
                .withUrl(global.URL_BASE + global.CAMINHO_SIGNALr)
                .build();
        
                connection.start();
        
                connection.on("ORG" + user.operador.idOrganizacao + "FIL" + user.operador.idFilial + "CX" + user.operador.numCaixa, (data) => {
        
                    //console.log("Recebido do SignalR: ", data); 
        
                    if(data == "LogOut"){
        
                        sessionStorage.removeItem("informacoesUsuario");
        
                        window.location.href = "/login";
        
                    }
        
                });
    
            }
        }

    }, [user]);

    return (

        <Container fluid className='bg-light' style={{maxWidth: "1000px", paddingBottom: "1%"}}>

            <ModalLoad showModal={showModalLoad} />
            <ModalSucesso showModal={showModalSucesso} setShowModal={setShowModalSucesso} msg={msgSucesso} />
            <ModalErro showModal={showModalErro} setShowModal={setShowModalErro} msg={msgErro} permiteFechar={false} />

            <ModalEmail

                showModal={showModalEmail}
                setShowModal={(e) => setShowModalEmail(e)}
                funcPos={funcPosEmail}
                Email={email}
                setEmail={(e) => setEmail(e)}

            />

            <ModalMotivoCancelamento

                showModal={showModalMotivoCancelamento}
                setShowModal={setShowMOdalMotivoCancelamento}
                motivoCancelamento={motivoCancelamento}
                setMotivoCancelamento={setMotivoCancelamento}
                funcPos={funcPosMotivoCancelamento}

            />

            <NavbarOp user={user} />

            <Row style={{marginTop: "1%", alignContent: "center", alignItems: "center", textAlign: "center"}}>

                <h1>Relatorio de Notas fiscais</h1>

            </Row>

            <Row style={{marginTop: "1%"}}>
                <Col
                
                xs={6}
                sm={6}
                md={6}

                
                >
                    <FloatingLabel
                        label="Numero NFC-e: "
                        className="mb-3"
                    >
                        <Form.Control 
                            type="text"
                            value={numNFCe} 
                            onChange={(e) => {

                                setnumNFCe(e.target.value);

                            }}
                        />
                    </FloatingLabel>
                </Col>
                <Col

                xs={6}
                sm={6}
                md={6}
                
                >
                    <FloatingLabel
                        label="Documento do cliente: "
                        className="mb-3"
                    >
                        <Form.Control 
                            type="text"
                            value={documentoCliente} 
                            onChange={(e) => {
                                
                                setDocumentoCliente(e.target.value);

                            }}
                        />
                    </FloatingLabel>
                </Col>
                <Col
                
                xs={6}
                sm={6}
                md={6}
                
                >
                    <FloatingLabel
                        label="De: "
                        className="mb-3"
                    >
                        <Form.Control 
                            type="date"
                            value={dateDe} 
                            max={dataAte}
                            onChange={(e) => {

                                if(e.target.value != null && e.target.value != ""){
                                    setdateDe(e.target.value);
                                }

                            }}
                        />
                    </FloatingLabel>
                </Col>
                <Col
                
                xs={6}
                sm={6}
                md={6}
                
                >
                    <FloatingLabel
                        label="Até: "
                        className="mb-3"
                    >
                        <Form.Control 
                            type="date"
                            value={dataAte} 
                            max={new Date().getFullYear().toString() + "-" + ( (new Date().getMonth() + 1) <= 9 ? ("0" + (new Date().getMonth() + 1).toString()) : new Date().getMonth() + 1).toString()  + "-" + ( (new Date().getDate()) <= 9 ? ("0" + new Date().getDate().toString()) : (new Date().getDate().toString()) ).toString()}
                            onChange={(e) => {

                                if(e.target.value != null && e.target.value != ""){
                                    setdataAte(e.target.value);
                                }

                                

                            }}
                        />
                    </FloatingLabel>
                </Col>
            </Row>

            <Row 
            style={{marginBottom: "1%"}}
            >
                <Col>
                
                    <div className="d-grid gap-2">
                        <Button
                        variant="primary"
                        onClick={() => {

                            try{

                                setShowModalLoad(true);

                                let data = {

                                    idOrganizacao: user.operador.idOrganizacao,
                                    idFilial: user.operador.idFilial,
                                    numeroCaixa: user.operador.numCaixa,
                                    NumNFCe: numNFCe == "" ? null : numNFCe,
                                    CPFCNPJ: documentoCliente == "" ? null : documentoCliente,
                                    dataDe: dateDe == "" ? null : dateDe,
                                    dataAte: dataAte == "" ? null : dataAte,

                                };

                                console.log(JSON.stringify(data));
                                console.log(data);

                                axios.post(global.URL_BASE + global.CAMINHO_LISTANFCES, data)
                                .then(function (response) {

                                    let retorno = response.data;

                                    if(retorno.code == 0){

                                        setShowModalLoad(false);
                                        setShowModalSucesso(true);
                                        setMsgSucesso("Cupons listados com sucesso!");

                                        setnfces(retorno.content);

                                    }else{

                                        console.log(retorno.msg);

                                        setShowModalLoad(false);
                                        setMsgErro(retorno.msg);
                                        setShowModalErro(true);

                                        setnfces([]);

                                    }
                                    
                                })
                                .catch(function (error) {

                                    Sentry.captureException(error);
                                    
                                    setShowModalLoad(false);
                                    setMsgErro(error.message);
                                    setShowModalErro(true);

                                    setnfces([]);

                                });

                            }catch(err){

                                Sentry.captureException(err);

                                console.log(err);

                                setShowModalLoad(false);
                                setMsgErro(err.message);
                                setShowModalErro(true);
                                

                                setnfces([]);

                            }

                        }}
                        >Buscar</Button>{' '}
                    </div>
                
                </Col>
            </Row>

            <hr style={{marginTop: "2%" , height: "3px", backgroundColor: "#000"}} />

            {nfces.map((nfce) => {

                return (

                    <Row key={nfce.idNFCe}>

                        <Col
                        
                        xs={6}
                        sm={6}
                        md={6}
                        
                        >
                        
                            <FloatingLabel
                                label="N.: "
                                className="mb-3"
                            >
                                <Form.Control 
                                    type="text"
                                    defaultValue={nfce.numeroNFCe}
                                    readOnly
                                />
                            </FloatingLabel>
                        
                        </Col>

                        <Col
                        
                        xs={6}
                        sm={6}
                        md={6}
                        
                        >
                        
                            <FloatingLabel
                                label="Data Emissão: "
                                className="mb-3"
                            >
                                <Form.Control 
                                    type="date"
                                    defaultValue={nfce.dataEmissao.split("T")[0] }
                                    readOnly
                                />
                            </FloatingLabel>
                        
                        </Col>

                        <Col
                        
                        xs={6}
                        sm={6}
                        md={6}
                        
                        >
                            
                            <FloatingLabel
                                label="Documento: "
                                className="mb-3"
                            >
                                <Form.Control 
                                    type="text"
                                    defaultValue={nfce.cpfcnpj}
                                    readOnly
                                />
                            </FloatingLabel>
                        
                        </Col>
                        
                        <Col
                        
                        xs={6}
                        sm={6}
                        md={6}
                        
                        >
                        
                            <FloatingLabel
                                label="Chave: "
                                className="mb-3"
                            >
                                <Form.Control 
                                    type="text"
                                    defaultValue={nfce.chave}
                                    readOnly
                                />
                            </FloatingLabel>
                        
                        </Col>

                        <Col
                        
                        xs={6}
                        sm={6}
                        md={6}
                        
                        >
                            
                            <FloatingLabel
                                label="Status Autorização: "
                                className="mb-3"
                            >
                                <Form.Control 
                                    type="text"
                                    defaultValue={nfce.statusAutorizacao != null ? (nfce.statusAutorizacao == 1 && "Pendente") || (nfce.statusAutorizacao == 2 && "Autorizado") || (nfce.statusAutorizacao == 3 && "Rejeitado") || (nfce.statusAutorizacao == 4 && "Denegado") || (nfce.statusAutorizacao == 5 && "Encerado") || (nfce.statusAutorizacao == 6 && "Cancelado") || (nfce.statusAutorizacao == 7 && "Erro") : "Erro"}
                                    readOnly
                                />
                            </FloatingLabel>
                        
                        </Col>
                        
                        <Col
                        
                        xs={6}
                        sm={6}
                        md={6}
                        
                        >
                        
                            <FloatingLabel
                                label="Protocolo Autorização: "
                                className="mb-3"
                            >
                                <Form.Control 
                                    type="text"
                                    defaultValue={nfce.autorizacaoProtocolo}
                                    readOnly
                                />
                            </FloatingLabel>
                        
                        </Col>

                        {
                            nfce.statusAutorizacao == 2
                            ?
                            <div>

                                <Col
                                
                                xs={12}
                                sm={12}
                                md={12}
                                
                                >
                            
                                    <div className="d-grid gap-2">
                                        <Button
                                        onClick={
                                            () => {
                                                    
                                                setShowModalLoad(true);

                                                let data = {
                                                    cnpjEmpresa: user.operador.filialSelecionada.empresaCNPJ,
                                                    uuidNota: nfce.uuidNfce
                                                };

                                        
                                                axios.post(global.URL_BASE + global.CAMINHO_BAIXARNFCE, data)
                                                .then((response) => {

                                                    let resultData = response.data;

                                                    console.log("RESULT DATA: ", response);

                                                    if(resultData.code == 0){

                                                        setShowModalLoad(false);
                                                        // setShowModalSucesso(true);
                                                        // setMsgSucesso("Cupom enviado com sucesso!")

                                                        const linkSource = `data:application/pdf;base64,${resultData.content}`;
                                                        const downloadLink = document.createElement("a");
                                                        const fileName = "file.pdf";

                                                        downloadLink.href = linkSource;
                                                        downloadLink.download = fileName;
                                                        downloadLink.click();

                                                    }else{

                                                        setShowModalLoad(false);
                                                        setShowModalErro(true);
                                                        setMsgErro("Erro ao baixar NFC-e.")

                                                    }
                                                    
                                                })
                                                .catch( (error) => {

                                                    Sentry.captureException(error);

                                                    setShowModalLoad(false);
                                                    setShowModalErro(true);
                                                    //setShowModalEmail(false);
                                                    setMsgErro(error.message);

                                                });
                                                

                                            }
                                        }>
                                        Baixar NFC-e
                                        </Button>{' '}
                                    </div>
                                
                                </Col>
                                <Col
                        
                                xs={12}
                                sm={12}
                                md={12}

                                style={{marginTop: "1%" }}

                                >
                                
                                    <div className="d-grid gap-2">
                                        <Button
                                        onClick={
                                            () => {
                                                    
                                                const funcEnviarEmailNFCe = (emailParam) => {

                                                    setShowModalLoad(true);

                                                    let data = {
                                                        uuid: nfce.uuidNfce,
                                                        email: emailParam,
                                                        cnpj: user.operador.filialSelecionada.empresaCNPJ
                                                    };
                                            
                                                    console.log("Data0: ", data);
                                                    
                                                    axios.post(global.URL_BASE + global.CAMINHO_ENVIAREMAILNFCE, data)
                                                    .then( (response) => {

                                                        let resultData = response.data;

                                                        console.log("RESULT DATA: ", response);

                                                        if(resultData.code == 0){

                                                            setShowModalSucesso(true);
                                                            setMsgSucesso("Cupom enviado com sucesso!");

                                                        }else{

                                                            setShowModalErro(true);
                                                            //setShowModalEmail(false);
                                                            setMsgErro(resultData.msg);
                                                        }
                                                        
                                                    })
                                                    .catch( (error) => {

                                                        Sentry.captureException(error);

                                                        setShowModalErro(true);
                                                        setMsgErro(error.message);

                                                    })
                                                    .finally(() => {

                                                        setShowModalLoad(false);
                                                        setShowModalEmail(false);
                                                        setEmail("");

                                                    });

                                                }

                                                setFuncPosEmail((e) => funcEnviarEmailNFCe);
                                                setShowModalEmail(true);
                                                

                                            }
                                        }>
                                        Enviar por email
                                        </Button>{' '}
                                    </div>
                                
                                </Col>
                                <Col
                        
                                xs={12}
                                sm={12}
                                md={12}

                                style={{marginTop: "1%" }}

                                >
                                
                                    <div className="d-grid gap-2">
                                        <Button
                                        onClick={
                                            () => {
                                                    
                                                const funcEnviarCancelamento = (motivoCancelamentoInput) => {

                                                    setShowModalLoad(true);

                                                    let data = {
                                                        uuid: nfce.uuidNfce,
                                                        motivo: motivoCancelamentoInput,
                                                        cnpjEmit: user.operador.filialSelecionada.empresaCNPJ
                                                    };
                                                    
                                                    axios.post(global.URL_BASE + global.CAMINHO_CANCELARNFCE, data)
                                                    .then( (response) => {

                                                        let resultData = response.data;

                                                        if(resultData.code == 0){

                                                            setMsgSucesso("Cupom cancelado com sucesso!");
                                                            setShowModalSucesso(true);

                                                        }else{

                                                            setMsgErro(resultData.msg);
                                                            setShowModalErro(true);

                                                        }
                                                        
                                                    })
                                                    .catch( (error) => {

                                                        Sentry.captureException(error);

                                                        setShowModalErro(true);
                                                        setMsgErro(error.message);

                                                    })
                                                    .finally(() => {

                                                        setShowModalLoad(false);
                                                        setShowModalEmail(false);
                                                        setMotivoCancelamento("");

                                                    });

                                                }

                                                setFuncPosMotivoCancelamento((e) => funcEnviarCancelamento);
                                                setShowMOdalMotivoCancelamento(true);
                                                
                                            }
                                        }>
                                        Cancelar NFC-e
                                        </Button>{' '}
                                    </div>
                                
                                </Col>
                            </div>
                            :null
                        }

                        <hr style={{marginTop: "1%" }} />

                    </Row>

                );

            })}

        </Container>

    );

}
