import { 
    Modal
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { BiError } from 'react-icons/bi';

ModalErro.propTypes = {

    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
    msg: PropTypes.string.isRequired,
    permiteFechar: PropTypes.bool.isRequired

}

export default function ModalErro({showModal, setShowModal, msg, permiteFechar}) {

    return(

        <Modal
        show={showModal}
        onHide={() => { !permiteFechar ? setShowModal(false) : setShowModal(true) } }
        keyboard={false}
        id="modalErro"
        centered
        >
            <Modal.Header closeButton>
                <Modal.Title><h1><BiError /> Erro !</h1></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {msg}
            </Modal.Body>
        </Modal>

    );

}
