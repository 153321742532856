import React from 'react';
import { 
    Button, 
    Container, 
    Navbar,
    Offcanvas,
    Nav
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import ModalDeslogar from './ModalDeslogar';
import ModalAlterarSenhaOperador from './ModalAlterarSenhaOperador';

NavbarOp.propTypes = {

    user: PropTypes.object.isRequired

}

export default function NavbarOp({user}) {

    const [showModalDeslogar, setShowModalDeslogar] = React.useState(false);
    const [showModalAlterarSenha, setShowModalAlterarSenha] = React.useState(false);

    return(

        <div>

            <ModalDeslogar showModal={showModalDeslogar} setShowModal={setShowModalDeslogar}/>

            <Navbar 
            expand={false} 
            className='rounded'
            style={{width: "100%", backgroundColor: "#dddddd"}}
            >
                <Container fluid>

                    <ModalAlterarSenhaOperador showModal={showModalAlterarSenha} setShowModal={ (e) => { setShowModalAlterarSenha(e) } } user={user} />

                    <Navbar.Brand><h2>Bem-vindo{", " + user.operador.nomeOperador} ! </h2></Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${false}`} />
                    <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-${false}`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-${false}`}
                    placement="end"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                                <h1>Caixa{" " + user.operador.numCaixa}</h1>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <hr />
                        <Offcanvas.Body>

                        <Nav.Item
                            
                            style={{

                                marginTop: "1%",
                                marginBottom: "1%",

                            }}
                            
                            >
                                <div className="d-grid gap-2">
                                    <Button
                                    variant={"primary"}
                                    onClick={ () => {

                                        window.location.href = "/";

                                    }}
                                    
                                    ><h5>Home</h5></Button>{' '}
                                </div>
                            </Nav.Item>

                            <Nav.Item
                            
                            style={{

                                marginTop: "1%",
                                marginBottom: "1%",

                            }}
                            
                            >
                                <div className="d-grid gap-2">
                                    <Button
                                    variant={"primary"}
                                    onClick={ () => {

                                        window.location.href = "/listavendas";

                                    }}
                                    
                                    ><h5>Consultar vendas já realizadas</h5></Button>{' '}
                                </div>
                            </Nav.Item>

                            <Nav.Item
                            
                            style={{

                                marginTop: "1%",
                                marginBottom: "1%",

                            }}
                            
                            >
                                <div className="d-grid gap-2">
                                    <Button
                                    variant={"primary"}
                                    onClick={ () => {

                                        window.location.href = "/listanfces";

                                    }}
                                    
                                    ><h5>Consultar notas emitidas</h5></Button>{' '}
                                </div>
                            </Nav.Item>

                            <hr />

                            <Nav.Item

                            style={{
                                marginBottom: "1%",
                            }}

                            >
                                
                                <div className="d-grid gap-2">
                                    <Button onClick={
                                        () => {
                                            setShowModalAlterarSenha(true);
                                        }
                                    }>
                                        <h5>Alterar Senha</h5>
                                    </Button>
                                </div>

                            </Nav.Item>

                            <Nav.Item

                            style={{
                                marginBottom: "1%",
                            }}

                            >
                                
                                <div className="d-grid gap-2">
                                    <Button 
                                    variant='danger'
                                    onClick={
                                        () => {

                                            setShowModalDeslogar(true);

                                        }}
                                    >
                                        <h5>Sair</h5>
                                    </Button>
                                </div>

                            </Nav.Item>

                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>

        </div>

        

    );

}