import React, { useEffect } from 'react'
import { 
    Button, 
    Form, 
    Container, 
    Row, 
    Col, 
    FloatingLabel ,
    Modal,
    Spinner
} from 'react-bootstrap';
import axios from 'axios';
import * as Sentry from "@sentry/react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BiError } from "react-icons/bi";
import { CiSquareCheck } from "react-icons/ci";
import global from '../global';

export default function Login() {

    const [user] = React.useState( JSON.parse(sessionStorage.getItem("informacoesUsuario")) );
    
    const [usuarioOperador, setUsuarioOperador] = React.useState("");
    const [numCaixa, setNumCaixa] = React.useState("");
    const [senhaOperador, setSenhaOperador] = React.useState("");
    const [showModalLoad, setShowModalLoad] = React.useState(false);
    const [showModalError, setShowModalError] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [operadores, setOperadores] = React.useState([]);

    useEffect(() => {
        if(user !== null){
            if(user.autorizado){
                window.location.href = "/";
            }
        }
    });

    return (
        <div>
        {
            operadores != null && operadores != undefined && operadores.length > 0
            ?<Container className='mx-auto border border-dark bg-white' style={{width: "75%", marginTop: "5%", marginBottom: "5%"}}>
                <Modal
                show={showModalLoad}
                //onHide={handleClose}
                backdrop="static"
                keyboard={false}
                id="modalLoad"
                centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Carregando...</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </Modal.Body>
                </Modal>

                <Modal
                show={showModalError}
                onHide={() => setShowModalError(false)}
                keyboard={false}
                id="modalLoad"
                centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title><h1><BiError /> Erro !</h1></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {errorMsg}
                    </Modal.Body>
                </Modal>

                {/*console.log(operadores)*/}

                <Container style={{  marginBottom: "10%"}}>

                    <Row style={{ marginTop: "5%", marginBottom: "1%"}}>
                        <Col><h5>Olá, {usuarioOperador}: </h5></Col>
                    </Row>
                    <Row style={{ marginTop: "1%", marginBottom: "5%"}}>
                        <Col><h5>Selecione a Filial: </h5></Col>
                    </Row>

                    {operadores.map((opera, index) => {

                        console.log("Opera " + index +  ": ", opera)

                        return <Row key={index}>
                            <Col
                            xs={12}
                            sm={9}
                            md={9}
                            lg={9}
                            xl={9}
                            className=''
                            style={{textAlign: "center"}}
                            >
                                <FloatingLabel
                                label="Nome da filial: "
                                className="mb-3"
                                >
                                    <Form.Control 
                                        type="text" 
                                        placeholder="" 
                                        defaultValue={opera.filiaisDados[0].empresaFantasia == "" || opera.filiaisDados[0].empresaFantasia == null || opera.filiaisDados[0].empresaFantasia == undefined ? "Nome não encontrado" : opera.filiaisDados[0].empresaFantasia} 
                                        disabled
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col
                            xs={12}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                            >
                                <div className="d-grid gap-2" >
                                    <Button
                                    
                                    onClick={
                                        async () => {

                                            try{

                                                setShowModalLoad(true);
                                                
                                                let result = await axios.post(global.URL_BASE + global.CAMINHO_LISTARFORMASPAGAMENTOS, {

                                                    idOrganizacao: opera.idOrganizacao,
                                                    idFilial: opera.idFilial,
                                                    numCaixa: numCaixa,
                                                    userWS: global.USER_WS,
                                                    passWS: global.PASS_WS

                                                })
                                                .then((response) => {

                                                    let resultData = response.data;

                                                    //console.log('Data', resultData);

                                                    if(resultData.code === 0){

                                                        let infoOperadores = {
                                                            logado: true,
                                                            formasDePagamentos: resultData.content.formasPagamentoFilial,
                                                            operador: opera
                                                        }

                                                        //console.log(result.data);

                                                        infoOperadores.operador.numCaixa = numCaixa;
                                                        infoOperadores.operador.userWS = global.USER_WS;
                                                        infoOperadores.operador.passWS = global.PASS_WS;
                                                        infoOperadores.operador.codOperador = opera.idOperador;
                                                        infoOperadores.operador.filialSelecionada = opera.filiaisDados[0];
                                                    

                                                        sessionStorage.setItem("informacoesUsuario", JSON.stringify(infoOperadores));
                                                        window.location.href = "/";

                                                    }else{

                                                        setShowModalError(true);
                                                        setErrorMsg(resultData.msg);

                                                    }
                                                    
                                                })
                                                .catch((error) => {
                                                    Sentry.captureException(error);

                                                    setShowModalError(true);
                                                    setErrorMsg('Ocorreu algum erro inesperado, tente novamente!');

                                                })
                                                .finally(() => {
                                                    setShowModalLoad(false);
                                                });

                                            }catch(ex){

                                                Sentry.captureException(ex);

                                                setShowModalError(true);
                                                setErrorMsg('Ocorreu algum erro inesperado, tente novamente!');
                                                setShowModalLoad(false);

                                            }
                                        }
                                    }>
                                        <CiSquareCheck size={45}/>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    })}
                </Container>
            </Container>
            :<Container className='mx-auto border border-dark bg-white' style={{width: "75%", marginTop: "5%", marginBottom: "5%"}}>
                <Modal
                show={showModalLoad}
                //onHide={handleClose}
                backdrop="static"
                keyboard={false}
                id="modalLoad"
                centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Carregando...</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </Modal.Body>
                </Modal>

                <Modal
                show={showModalError}
                onHide={() => setShowModalError(false)} 
                keyboard={false}
                id="modalLoad"
                centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title><h1><BiError /> Erro !</h1></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {errorMsg}
                    </Modal.Body>
                </Modal>

                <Form >
                    <Row style={{textAlign: "center", marginTop: "5%"}}>
                        <Col><h1>Caixa</h1></Col>
                    </Row>
                    <Row style={{marginTop: "5%"}}>
                        <Col
                        
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        
                        >
                        
                            <FloatingLabel
                                label="Caixa: "
                                className="mb-3"
                            >
                                <Form.Control 
                                    type="number"
                                    step={"1"}
                                    min={100} 
                                    placeholder="User" 
                                    value={numCaixa} 
                                    onChange={(e) => {
                                        setNumCaixa(e.target.value);
                                    }}
                                />
                            </FloatingLabel>

                        </Col>
                    </Row>
                    <Row style={{marginTop: "5%"}}>

                        <Col
                        
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        
                        >
                        
                            <FloatingLabel
                                label="Usuario Operador: "
                                className="mb-3"
                            >
                                <Form.Control 
                                    type="text" 
                                    placeholder="User" 
                                    value={usuarioOperador} 
                                    onChange={(e) => {
                                        setUsuarioOperador(e.target.value);
                                    }}
                                />
                            </FloatingLabel>

                        </Col>

                        
                        
                    </Row>
                    <Row style={{marginTop: "5%"}}>

                        <Col
                        
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        
                        >
                        
                            <FloatingLabel
                                label="Senha operador: "
                                className="mb-3"
                            >
                                <Form.Control 
                                    type="password" 
                                    placeholder="User" 
                                    value={senhaOperador} 
                                    onChange={(e) => {
                                        setSenhaOperador(e.target.value);
                                    }}
                                />
                            </FloatingLabel>

                        </Col>
                        
                    </Row>
                    <Row style={{marginTop: "5%"}}>

                        <Col>
                        
                            <div className="d-grid gap-2">
                                <Button
                                variant="primary"
                                onClick={ () => {

                                    try{

                                        if(isNaN(parseInt(numCaixa)) || numCaixa === '' || senhaOperador == '' || usuarioOperador == ''){

                                            return;

                                        }

                                        setShowModalLoad(true);
                                        
                                        axios.post(global.URL_BASE + global.CAMINHO_LOGIN, {
                                            
                                            usuarioOperador: usuarioOperador,
                                            senhaOperador: senhaOperador,
                                            numeroCx: numCaixa,
                                            userWS: global.USER_WS,
                                            passWS: global.PASS_WS

                                        })
                                        .then((response) => {

                                            let resultData = response.data;

                                            //console.log('ResultData LOGIN: ',resultData);

                                            if(resultData.code === 0){

                                                setOperadores(resultData.content.operadores);

                                            }else{
                                                
                                                setShowModalError(true);
                                                setErrorMsg(resultData.msg);         

                                            }
                                            
                                        })
                                        .catch((error) => {
                                            Sentry.captureException(error);

                                            setShowModalError(true);
                                            setErrorMsg('Ocorreu algum erro inesperado, tente novamente!');
                                        
                                        }).finally(() => {
                                            setShowModalLoad(false);
                                        });

                                    }catch(ex){

                                        Sentry.captureException(ex);

                                        setShowModalError(true);
                                        setErrorMsg('Ocorreu algum erro inesperado, tente novamente!');

                                    }

                                }}
                                >Login</Button>{' '}
                            </div>
                        
                        </Col>

                    </Row>
                    <Row style={{marginTop: "5%"}}/>
                </Form>
                
            </Container>
        }
        </div>
    );


}
