import React, { useEffect } from 'react';
import * as SignalR from '@microsoft/signalr'
import { 
    Button, 
    Form, 
    Container, 
    Row, 
    Col, 
    FloatingLabel
} from 'react-bootstrap';
import * as Sentry from "@sentry/react";
import ReactToPrint from 'react-to-print';
import axios from 'axios';
import global from '../global';
import ModalLoad from '../components/ModalLoad';
import ModalSucesso from '../components/ModalSucesso';
import ModalErro from '../components/ModalErro';
import ModalEmail from '../components/ModalEmail';
import NavbarOp from '../components/Navbar';
import ModalConfimacao from '../components/ModalConfimacao';

export default function ListaVendas(){

    //user op
    const [user] = React.useState( JSON.parse(sessionStorage.getItem("informacoesUsuario")) );

    //modal lista de cupons
    const [cuponsCupomModalListaCupons, setcuponsCupomModalListaCupons] = React.useState([]);
    const [numeroCupomModalListaCupons, setnumeroCupomModalListaCupons] = React.useState("");
    const [documentoCupomModalListaCupons, setdocumentoCupomModalListaCupons] = React.useState("");
    const [dateDeCupomModalListaCupons, setdateDeCupomModalListaCupons] = React.useState(new Date().getFullYear().toString() + "-" + ( (new Date().getMonth() + 1) <= 9 ? ("0" + (new Date().getMonth() + 1).toString()) : new Date().getMonth() + 1).toString()  + "-" + ( (new Date().getDate()) <= 9 ? ("0" + new Date().getDate().toString()) : (new Date().getDate().toString()) ).toString());
    const [dataAteCupomModalListaCupons, setdataAteCupomModalListaCupons] = React.useState(new Date().getFullYear().toString() + "-" + ( (new Date().getMonth() + 1) <= 9 ? ("0" + (new Date().getMonth() + 1).toString()) : new Date().getMonth() + 1).toString()  + "-" + ( (new Date().getDate()) <= 9 ? ("0" + new Date().getDate().toString()) : (new Date().getDate().toString()) ).toString());

    const [refsCupomImprimir, setrefsCupomImprimir] = React.useState([]);

    //modal load
    const [showModalLoad, setShowModalLoad] = React.useState(false);

    //modal sucesso
    const [showModalSucesso, setShowModalSucesso] = React.useState(false);
    const [msgSucesso, setMsgSucesso] = React.useState("");

    //modal erro
    const [showModalErro, setShowModalErro] = React.useState(false);
    const [msgErro, setMsgErro] = React.useState("");

    //modal email
    const [showModalEmail, setShowModalEmail] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const [funcPosEmail, setFuncPosEmail] = React.useState( () => () => {} );

    //modal confirmar acao
    const [showModalConfimacao, setShowModalConfimacao] = React.useState(false);
    const [msgModalConfirmarAcao, setmsgModalConfirmarAcao] = React.useState("Deseja realmente re-emitir NFC-e deste cupom?");
    const [funcPosConfimacao, setFuncPosConfimacao] = React.useState( () => () => {} );



    if(user == null || user == undefined){
        window.location.href = "/login";
    }

    //console.log(cuponsCupomModalListaCupons);
    //console.log(refsCupomImprimir);
    console.log(email);
    //console.log("funcPosEmail", funcPosEmail);

    useEffect(() => {

        if(user === null || user === undefined){
            window.location.href = "/login";
        }else{
            if(user.autorizado === false){
                window.location.href = "/login";
            }else{
    
                let connection = new SignalR.HubConnectionBuilder()
                .withUrl(global.URL_BASE + global.CAMINHO_SIGNALr)
                .build();
        
                connection.start();
        
                connection.on("ORG" + user.operador.idOrganizacao + "FIL" + user.operador.idFilial + "CX" + user.operador.numCaixa, (data) => {
        
                    //console.log("Recebido do SignalR: ", data); 
        
                    if(data == "LogOut"){
        
                        sessionStorage.removeItem("informacoesUsuario");
        
                        window.location.href = "/login";
        
                    }
        
                });
    
            }
        }

    }, [user]);

    return(

        <Container fluid className='bg-light' style={{maxWidth: "1000px", paddingBottom: "1%"}}>

            <ModalLoad showModal={showModalLoad} />
            <ModalSucesso showModal={showModalSucesso} setShowModal={setShowModalSucesso} msg={msgSucesso} />
            <ModalErro showModal={showModalErro} setShowModal={setShowModalErro} msg={msgErro} permiteFechar={false} />
            <ModalEmail 
                showModal={showModalEmail} 
                setShowModal={ (e) => { setShowModalEmail(e); } } 
                funcPos={funcPosEmail} 
                Email={email} 
                setEmail={ (e) => { setEmail(e); } } 
            />
            <ModalConfimacao
                showModal={showModalConfimacao}
                setShowModal={setShowModalConfimacao}
                funcPos={funcPosConfimacao}
                msgModalConfirmarAcao={msgModalConfirmarAcao}
            />

            <NavbarOp user={user} />

            <Row style={{marginTop: "1%", alignContent: "center", alignItems: "center", textAlign: "center"}}>

                <h1>Relatorio de Vendas</h1>

            </Row>

            <Row style={{marginTop: "1%"}}>
                <Col
                xs={6}
                sm={6}
                md={6}
                >
                    <FloatingLabel
                        label="Numero Cupom: "
                        className="mb-3"
                    >
                        <Form.Control 
                            type="text"
                            value={numeroCupomModalListaCupons} 
                            onChange={(e) => {

                                setnumeroCupomModalListaCupons(e.target.value);

                            }}
                        />
                    </FloatingLabel>
                </Col>
                <Col
                xs={6}
                sm={6}
                md={6}
                >
                    <FloatingLabel
                        label="Documento: "
                        className="mb-3"
                    >
                        <Form.Control 
                            type="text"
                            value={documentoCupomModalListaCupons} 
                            onChange={(e) => {
                                
                                setdocumentoCupomModalListaCupons(e.target.value);

                            }}
                        />
                    </FloatingLabel>
                </Col>
                <Col
                xs={6}
                sm={6}
                md={6}
                >
                    <FloatingLabel
                        label="De: "
                        className="mb-3"
                    >
                        <Form.Control 
                            type="date"
                            value={dateDeCupomModalListaCupons} 
                            max={new Date().getFullYear().toString() + "-" + ( (new Date().getMonth() + 1) <= 9 ? ("0" + (new Date().getMonth() + 1).toString()) : new Date().getMonth() + 1).toString()  + "-" + ( (new Date().getDate()) <= 9 ? ("0" + new Date().getDate().toString()) : (new Date().getDate().toString()) ).toString()}
                            onChange={(e) => {
                                if(e.target.value != null && e.target.value != ""){
                                    setdateDeCupomModalListaCupons(e.target.value);
                                }
                                

                            }}
                        />
                    </FloatingLabel>
                </Col>
                <Col
                xs={6}
                sm={6}
                md={6}
                >
                    <FloatingLabel
                        label="Até: "
                        className="mb-3"
                    >
                        <Form.Control 
                            type="date"
                            value={dataAteCupomModalListaCupons} 
                            max={new Date().getFullYear().toString() + "-" + ( (new Date().getMonth() + 1) <= 9 ? ("0" + (new Date().getMonth() + 1).toString()) : new Date().getMonth() + 1).toString()  + "-" + ( (new Date().getDate()) <= 9 ? ("0" + new Date().getDate().toString()) : (new Date().getDate().toString()) ).toString()}
                            onChange={(e) => {

                                if(e.target.value != null && e.target.value != ""){
                                    setdataAteCupomModalListaCupons(e.target.value);
                                }

                            }}
                        />
                    </FloatingLabel>
                </Col>
            </Row>

            <Row 
            style={{marginBottom: "1%"}}
            >
                <Col>
                
                    <div className="d-grid gap-2">
                        <Button
                        variant="primary"
                        onClick={async () => {

                            try{

                                // this.setState({
                                //     showModalLoad: true
                                // });

                                setShowModalLoad(true);

                                let data = {

                                    idOrganizacao: user.operador.idOrganizacao,
                                    idFilial: user.operador.idFilial,
                                    numeroCaixa: user.operador.numCaixa,
                                    numeroCupom: numeroCupomModalListaCupons == "" ? 0 : numeroCupomModalListaCupons,
                                    documento: documentoCupomModalListaCupons,
                                    dataDe: dateDeCupomModalListaCupons == "" ? null : dateDeCupomModalListaCupons,
                                    dataAte: dataAteCupomModalListaCupons == "" ? null : dataAteCupomModalListaCupons,
                                    userWS: global.USER_WS,
                                    senhaWS: global.PASS_WS,
                                    idCliente: 0,
                                    tipoCupom: "NF"

                                };

                                console.log(JSON.stringify(data));
                                console.log(data);

                                let result = await axios.post(global.URL_BASE + global.CAMINHO_LISTACUPONSINPRESSAO, data)
                                .then(function (response) {
                                    
                                    return {type: "Sucess", data: response.data};
                                    
                                })
                                .catch(function (error) {
                                    Sentry.captureException(error);
                                    return {type: "Error", data: error};
                                });

                                if(result.type === "Sucess"){

                                    let retorno = result.data;

                                    console.log("AQUIIIIIIIIIIIIIIIIIIIIIIIIIIII", retorno);

                                    if(retorno.code == 0){

                                        setShowModalLoad(false);
                                        setShowModalSucesso(true);
                                        setMsgSucesso("Cupons listados com sucesso!");

                                        setcuponsCupomModalListaCupons(retorno.content);

                                    }else{

                                        console.log(retorno.msg);
                                        
                                        // this.setState({
                                        //     showModalLoad: false,
                                        //     showModalErro: true,
                                        //     errorMsg: retorno.msg
                                        // });

                                        setShowModalLoad(false);
                                        setShowModalErro(true);
                                        setMsgErro(retorno.msg);

                                    }

                                    
                                
                                }else{

                                    // this.setState({
                                    //     showModalLoad: false,
                                    //     showModalErro: true,
                                    //     errorMsg: 'Ocorreu um erro ao adicionar o item ao pedido!'
                                    // });

                                    setShowModalLoad(false);
                                    setShowModalErro(true);
                                    setMsgErro('Ocorreu um erro ao adicionar o item ao pedido!');

                                }

                                if(result.type === "Error"){

                                    console.log(result.data.message);

                                    // this.setState({
                                    //     showModalLoad: false,
                                    //     showModalErro: true,
                                    //     errorMsg: result.data.message
                                    // });

                                    setShowModalLoad(false);
                                    setShowModalErro(true);
                                    setMsgErro(result.data.message);
                                
                                }

                            }catch(err){

                                Sentry.captureException(err);

                                console.log(err);

                                // this.setState({
                                //     showModalLoad: false,
                                //     showModalErro: true,
                                //     errorMsg: err.message
                                // });

                                setShowModalLoad(false);
                                setShowModalErro(true);
                                setMsgErro(err.message);

                            }

                        }}
                        >Buscar</Button>{' '}
                    </div>
                
                </Col>
            </Row>

            <hr style={{marginTop: "2%" , height: "3px", backgroundColor: "#000"}} />

            {cuponsCupomModalListaCupons.map((cupom, index) => {

                console.log(cupom.data);

                // Separar a data e a hora
                let [datePart, timePart] = cupom.data.split(' ');

                // Reformatar a data de "dd/MM/yyyy" para "yyyy-MM-dd"
                let [day, month, year] = datePart.split('/');
                let formattedDate = `${year}-${month}-${day}`;

                // Pegar apenas horas e minutos de "HH:mm:ss"
                let [hours, minutes] = timePart.split(':');

                // Combinar data e hora no formato "yyyy-MM-ddTHH:mm"
                let formattedDateTime = `${formattedDate}T${hours}:${minutes}`;

                console.log(formattedDateTime)
;
                return (
            
                    <Row key={parseInt(cupom.numeroCupom)}>

                        <Container 
                        className='hidden' 
                        style={{display: "none" }}
                        >
                            <div 
                            id={"containerPdf" + index} 
                            ref={(refer) => {
                                
                                refsCupomImprimir[index] = refer;
                                setrefsCupomImprimir(refsCupomImprimir);
                            
                            }}
                            >
                               <div>
                                    <div style={{textAlign: "center", border: "1px solid black"}}>

                                        <h1>HOSTMUNDO</h1>

                                    </div>
                                </div>
                                <div>
                                    <div style={{textAlign: "center", alignItems: "center"}}>
                                    
                                        {user.operador.filialSelecionada.empresaEndLogradouro == null ? "" : user.operador.filialSelecionada.empresaEndLogradouro } {user.operador.filialSelecionada.empresaEndNumero == null ? "" : user.operador.filialSelecionada.empresaEndNumero}
                                    
                                    </div>
                                </div>
                                <div>
                                    <div style={{textAlign: "center", alignItems: "center"}}>
                                        {user.operador.filialSelecionada.empresaEndBairro == null ? "" : user.operador.filialSelecionada.empresaEndBairro}
                                    </div>
                                </div>
                                <div>
                                    <div style={{textAlign: "center", alignItems: "center"}}>
                                        {user.operador.filialSelecionada.empresaEndCidade == null ? "" : user.operador.filialSelecionada.empresaEndCidade} /{user.operador.filialSelecionada.empresaEndUF == null ? "" : user.operador.filialSelecionada.empresaEndUF}
                                    </div>
                                </div>
                                <div>
                                    <div style={{textAlign: "center", alignItems: "center"}}>

                                        TICKET N. {cupom.numeroCupom == null ? "" : cupom.numeroCupom}
                                        
                                    </div>
                                </div>
                                <div>
                                    <div >
                                        { cupom.data.split(" ")[0].split("/")[2] + "-" + cupom.data.split(" ")[0].split("/")[1] + "-" + cupom.data.split(" ")[0].split("/")[0] }
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <hr />
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <div>

                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                
                                            }}>
                                                <div style={{flex: 1, textAlign: "center", alignContent: "center", alignItems: "center", justifyContent: "center"}}>
                                                </div>
                                                <div style={{flex: 1, textAlign: "center", alignContent: "center", alignItems: "center", justifyContent: "center"}}>
                                                    ITEM CÓDIGO 
                                                </div>
                                                <div style={{flex: 1, textAlign: "center", alignContent: "center", alignItems: "center", justifyContent: "center"}}>
                                                    DESCRIÇÃO
                                                </div>
                                                <div style={{flex: 1, textAlign: "center", alignContent: "center", alignItems: "center", justifyContent: "center"}}>
                                                    Qtd
                                                </div>
                                                <div style={{flex: 1, textAlign: "center", alignContent: "center", alignItems: "center", justifyContent: "center"}}>
                                                    PREÇO
                                                </div>
                                                
                                                <div style={{flex: 1, textAlign: "center", alignContent: "center", alignItems: "center", justifyContent: "center"}}>
                                                    SUBTOTAL
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <hr />
                                                </div>
                                            </div>
                                            {cupom.itensCupom.map((item, index) => {
                                                
                                                return (
                                                    <div key={index} style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        border: "1% solid black"
                                                    }}>
                                                        <div style={{flex: 1, border: "1% solid black", textAlign: "center", alignContent: "center", alignItems: "center", justifyContent: "center"}}>
                                                            {item.sequencia}
                                                        </div>
                                                        <div style={{flex: 1, border: "1% solid black", textAlign: "center", alignContent: "center", alignItems: "center", justifyContent: "center"}}>
                                                            {item.codigoBarras}
                                                        </div>
                                                        <div style={{flex: 1, border: "1% solid black", textAlign: "center", alignContent: "center", alignItems: "center", justifyContent: "center"}}>
                                                            {item.nomeProduto}
                                                        </div>
                                                        <div style={{flex: 1, border: "1% solid black", textAlign: "center", alignContent: "center", alignItems: "center", justifyContent: "center"}}>
                                                            {item.qtd}
                                                        </div>
                                                        <div style={{flex: 1, border: "1% solid black", textAlign: "center", alignContent: "center", alignItems: "center", justifyContent: "center"}}>
                                                            {item.preco}
                                                        </div>
                                                        <div style={{flex: 1, border: "1% solid black", textAlign: "center", alignContent: "center", alignItems: "center", justifyContent: "center"}}>
                                                            {"R$ " + (parseFloat(item.preco) * item.qtd).toFixed(2).replaceAll('.', ',')}
                                                        </div>
                                                    </div>
                                                );
                                                
                                            })}
                                            <div>
                                                <div>
                                                    <hr />
                                                </div>
                                            </div>

                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                
                                            }}>
                                                <div>
                                                    Soma dos Itens: 
                                                </div>
                                                <div>
                                                    {cupom.valor.toFixed(2).replaceAll('.', ',')}
                                                </div>
                                            </div>
                                            
                                            {cupom.desconto > 0 && cupom.desconto != null && cupom.desconto != undefined 
                                            ?
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}>
                                                    <div>
                                                        Desconto:  
                                                    </div>
                                                    <div style={{justifyContent: "rigth", justifyItems: "rigth", justifySelf: "rigth"}}>
                                                        {"R$" + (cupom.desconto.toFixed(2).replaceAll('.', ','))}
                                                    </div>
                                                </div>
                                            :
                                            null}

                                                                                
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                
                                            }}>
                                                <div>
                                                    Total: 
                                                </div>
                                                <div>
                                                    {"R$" + (cupom.valor - cupom.desconto).toFixed(2).replaceAll('.', ',')}
                                                </div>
                                            </div>
                                            

                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}>
                                                <div>
                                                    Troco:  
                                                </div>
                                                <div style={{justifyContent: "rigth", justifyItems: "rigth", justifySelf: "rigth"}}>
                                                    {"R$" + cupom.troco.toFixed(2).replaceAll('.', ',')}
                                                </div>
                                            </div>
                                            <hr/>
                                            <div>
                                                <div>
                                                    Formas de Pagamento Utilizadas:
                                                </div>
                                            </div>

                                            <div >

                                                {cupom.finalizadorasCupom.map((item, index) => {

                                                    return <div key={index}>

                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                        }}>
                                                            <div style={{flex: 1, border: "1% solid black"}}>
                                                            
                                                                {item.formaPagamento}

                                                            </div>
                                                            <div style={{flex: 1, border: "1% solid black"}}>
                                                                {item.valorPago}
                                                            </div>
                                                        </div>

                                                    </div>

                                                })}

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <hr/>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}>
                                    <div>
                                        CPF: 
                                    </div>
                                    <div>
                                        {cupom.documento}
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <hr/>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}>
                                    <div>
                                        Operador:
                                    </div>
                                    <div>
                                        {cupom.idOperador + " " + cupom.operador + " CX " + cupom.numeroCaixa}
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <hr/>
                                    </div>
                                </div>
                                <div style={{textAlign: "center", alignItems: "center"}}>
                                    <div>
                                        Obrigado e Volte Sempre!
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <hr/>
                                    </div>
                                </div>
                                <div style={{textAlign: "center", alignItems: "center"}}>
                                    <h2>Obrigado pela Preferência</h2>
                                </div>
                            </div>
                        </Container>

                        <Col
                        
                        xs={6}
                        sm={6}
                        md={6}
                        >
                        
                            <FloatingLabel
                                label="N.: "
                                className="mb-3"
                            >
                                <Form.Control 
                                    type="text"
                                    defaultValue={cupom.numeroCupom}
                                    readOnly
                                />
                            </FloatingLabel>
                        
                        </Col>

                        <Col
                        
                        xs={6}
                        sm={6}
                        md={6}
                        
                        >
                        
                            <FloatingLabel
                                label="Data: "
                                className="mb-3"
                            >
                                <Form.Control 
                                    type="datetime-local"
                                    defaultValue={ formattedDateTime}
                                    readOnly
                                />
                            </FloatingLabel>
                        
                        </Col>

                        <Col
                        
                        xs={6}
                        sm={6}
                        md={6}
                        
                        >
                        
                            <FloatingLabel
                                label="Valor: "
                                className="mb-3"
                            >
                                <Form.Control 
                                    type="number"
                                    step={0.01}
                                    defaultValue={parseFloat(cupom.valor).toFixed(2)}
                                    readOnly
                                />
                            </FloatingLabel>
                        
                        </Col>

                        <Col
                        
                        xs={6}
                        sm={6}
                        md={6}
                        
                        >
                        
                            <FloatingLabel
                                label="Doc: "
                                className="mb-3"
                            >
                                <Form.Control 
                                    type="text"
                                    defaultValue={cupom.documento}
                                    readOnly
                                />
                            </FloatingLabel>
                        
                        </Col>

                        <Col
                        
                        xs={12}
                        sm={12}
                        md={12}

                        style={{marginTop: "1%"}}
                        
                        >

                            <ReactToPrint
                                content={() => refsCupomImprimir[index]}
                                trigger={() => (
                                    <div className="d-grid gap-2">
                                        <Button>
                                        Re-imprimir
                                        </Button>{' '}
                                    </div>
                                )}
                                onAfterPrint={() => {
                                    //this.setState({loadNfce: false})
                                    //window.location.reload();
                                }}

                                pageStyle={'@media print { body { -webkit-print-color-adjust: exact; } @page { size: A4; margin: 200mm !important } }'}
                                // @page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }

                            />
                        
                        </Col>

                        <Col
                        
                        xs={12}
                        sm={12}
                        md={12}

                        style={{marginTop: "1%"}}
                        
                        >
                        
                            <div className="d-grid gap-2">
                                <Button
                                onClick={
                                    () => {

                                        let funcEnviarEmail = (email) => {

                                            console.log("EMAIL DA FUNÇÂO: ", email);

                                            if(email != "" && email != null && email != undefined){
                                                
                                                setShowModalLoad(true);

                                                const element_cupom = document.getElementById('containerPdf' + index);

                                                let data = {
                                                    html: element_cupom.outerHTML,
                                                    email: email,
                                                    numCupom: parseInt(cupom.numeroCupom),
                                                    razaoSocial: user.operador.filialSelecionada.empresaRazaoSocial
                                                };
                                        
                                                axios.post(global.URL_BASE + global.CAMINHO_ENVIAREMAILNAOFISCAL, data)
                                                .then(function (response) {

                                                    let resultData = response.data;

                                                    //console.log(resultData);

                                                    if(resultData.code == 0){

                                                        setShowModalLoad(false);
                                                        setShowModalSucesso(true);
                                                        setShowModalEmail(false);
                                                        setMsgSucesso("Cupom enviado com sucesso!")

                                                    }else{

                                                        setShowModalLoad(false);
                                                        setShowModalErro(true);
                                                        setShowModalEmail(false);
                                                        setMsgErro("Erro ao enviar email.")

                                                    }
                                                    
                                                })
                                                .catch(function (error) {

                                                    Sentry.captureException(error);

                                                    setShowModalLoad(false);
                                                    setShowModalErro(true);
                                                    setShowModalEmail(false);
                                                    setMsgErro(error);

                                                });

                                            }else{

                                                setShowModalLoad(false);
                                                setShowModalErro(true);
                                                setShowModalEmail(false);
                                                setMsgErro("O email deve ser informado. Teste: " + email)

                                            }

                                        };

                                        setFuncPosEmail(() => funcEnviarEmail);
                                        setShowModalEmail(true);
                                        

                                    }
                                }>
                                Enviar email
                                </Button>{' '}
                            </div>
                        
                        </Col>

                        <Col
                        
                        xs={12}
                        sm={12}
                        md={12}

                        style={{marginTop: "1%"}}
                        
                        >
                        
                            <div className="d-grid gap-2">
                                <Button
                                onClick={
                                    async () => {

                                        const funcreemitir = async () => {

                                            setShowModalLoad(true);
                                    
                                            let data = {

                                                idOrganizacao: user.operador.idOrganizacao,
                                                nomeOrganizacao: user.operador.filialSelecionada.nomeOrganizacao,
                                                idFilial: user.operador.idFilial,
                                                nomeFilial: user.operador.filialSelecionada.empresaRazaoSocial,
                                                idOperador: user.operador.codOperador,
                                                nomeOperador: user.operador.nomeOperador,
                                                numeroCupom: cupom.numeroCupom,
                                                numeroCaixa: cupom.numeroCaixa,
                                                tipo: cupom.tipoCupom,
                                                userWS: global.USER_WS,
                                                senhaWS: global.PASS_WS,
                                                cnpjEmit: user.operador.filialSelecionada.empresaCNPJ,
                                                cpf_cnpj: cupom.documento,
                                                email: email,
                                                
                                            };

                                            //console.log(JSON.stringify(data));
                                    
                                            let result = await axios.post(global.URL_BASE + global.CAMINHO_EMITIRNFCe, data)
                                            .then(function (response) {
                                    
                                                return {type: "Sucess", data: response.data};
                                                
                                            })
                                            .catch(function (error) {
                                                Sentry.captureException(error);
                                                return {type: "Error", data: error};
                                            })
                                            .finally(() => {
                                                setShowModalConfimacao(false);
                                            });
                                    
                                            if(result.type === "Error"){

                                                setShowModalLoad(false);
                                                setShowModalErro(true);
                                                setMsgErro("Erro ao emitir NFCe.")
                                            
                                            }
                                    
                                            if(result.type === "Sucess"){

                                                let resultData = result.data;

                                                if(resultData.code == 0){
                                                    const byteCharacters = atob(resultData.content);
                                                    const byteNumbers = new Array(byteCharacters.length);
                                                    for (let i = 0; i < byteCharacters.length; i++) {
                                                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                                                    }
                                                    const byteArray = new Uint8Array(byteNumbers);
                                                    const blob = new Blob([byteArray], { type: 'application/pdf' });
                                                    const blobUrl = URL.createObjectURL(blob);

                                                    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

                                                    if (isMobile) {
                                                        // Cria um link de download temporário
                                                        const link = document.createElement('a');
                                                        link.href = blobUrl;
                                                        //link.download = 'documento.pdf';
                                                        document.body.appendChild(link);
                                                        link.click();
                                                        document.body.removeChild(link);
                                                    } else {
                                                        printJS({
                                                            printable: resultData.content,
                                                            type: "pdf",
                                                            base64: true
                                                        });
                                                    }

                                                    setShowModalLoad(false);
                                                    setShowModalSucesso(true);
                                                    setMsgSucesso(resultData.msg);

                                                }else{

                                                    setShowModalLoad(false);
                                                    setShowModalErro(true);
                                                    setMsgErro(resultData.msg)

                                                }
                                    
                                            }

                                        }

                                        setFuncPosConfimacao(() => funcreemitir);
                                        setmsgModalConfirmarAcao("Deseja realmente emitir NFC-e deste cupom?");
                                        setShowModalConfimacao(true);

                                    }
                                }>
                                Emitir NFCe
                                </Button>{' '}
                            </div>
                        
                        </Col>

                        <hr style={{marginTop: "2%"}}/>

                    </Row>

                ); 
            
            })
            }

        </Container>

    );

}
